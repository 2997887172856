.home-nhan-dien {
  position: relative;
  .hero {
    position: relative;
    padding: 178px 0 54px;

    @include for-sm {
      height: 100%;
      padding: 75px 0 46px;
    }

    .bg {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      overflow: hidden;
      z-index: -1;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }

    .content,
    .container-content,
    .content-banner {
      height: 100%;
    }

    .content-banner {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      max-width: 470px;
      .sub-title {
        font-size: 32px;
        font-weight: 500;
        line-height: 50px;
        margin-bottom: 0;
        @include for-sm {
          font-size: 28px;
          line-height: 32px;
        }
      }
      .title {
        color: #002245;
        font-size: 42px;
        font-weight: 700;
        line-height: 50px;
        margin-bottom: 24px;
        @include for-sm {
          font-size: 36px;
          line-height: 42px;
        }
      }

    }

    .btn-action {
      box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.05);
      box-sizing: border-box;
      border: 1px solid #002245;
      border-radius: 27px;
      font-weight: bold;
      font-size: 14px;
      line-height: 17px;
      text-align: center;
      color: #002245;
      padding: 16px 12px;
      width: 182px;
      height: 50px;
      max-width: 100%;
      display: block;
      cursor: pointer;
      -ms-transition: background-color 0.4s ease;
      -o-transition: background-color 0.4s ease;
      -moz-transition: background-color 0.4s ease;
      -webkit-transition: background-color 0.4s ease;
      transition: background-color 0.4s ease;
      &:hover {
        background-color: #ffc107;
      }
    }
  }

}
@include for-lg {
}

@include for-sm {
}
