.home-blog {
  background-color: #f2f2f2;
  padding-bottom: 32px;
  padding-top: 32px;
  &.home-tips {
    background-color: #fff;
    .post .content .desc {
      -webkit-line-clamp: 3;
      height: 72px;
      min-height: auto;
    }
  }
  .title-box {
    margin-bottom: 32px;
    @include flexbox();
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    .section-title {
      margin-bottom: 0;
      flex-grow: 1;
      padding-right: 10px;
    }
    .btn-viewmore {
      flex-shrink: 0;
    }
  }
  .slide-posts {
    min-height: 522px;
    overflow: hidden;
  }
  .owl-carousel.owl-drag .owl-item {
    background-color: transparent;
  }
  .owl-dots {
    margin-top: 32px;
    text-align: center;
    @include flexbox();
    align-items: center;
    justify-content: center;
    .owl-dot {
      display: block;
      & + .owl-dot {
        margin-left: 15px;
      }
      span {
        width: 8px;
        height: 8px;
        background-color: #707070;
        display: block;
        -webkit-border-radius: 4px;
        border-radius: 4px;
        opacity: 0.1;
      }
      &.active span {
        opacity: 1;
        background-color: $dark-blue;
      }
    }
  }
}
@mixin home-blog-md {
  .home-blog {
    .slide-posts {
      min-height: 366px;
    }
  }
}
@mixin home-blog-sm {
  .home-blog {
    padding-bottom: 32px;
    padding-top: 32px;
    &.home-tips {
      padding-bottom: 32px;
      .post .content .desc {
        height: 60px;
      }
    }
    .title-box {
      margin-bottom: 24px;
      -ms-align-items: flex-end;
      align-items: flex-end;
    }
    .slide-posts {
      min-height: 312px;
    }
    .owl-dots { 
      margin-top: 6px;
    }
    .slider-item {
      padding-bottom: 10px;
      background-color: transparent;
    }
    .post {
      -webkit-box-shadow: 2px 3px 6px rgba(10, 31, 68, 0.1);
      box-shadow: 2px 3px 6px rgba(10, 31, 68, 0.1);
    }
  } 
}
@mixin home-blog-xs {
  .home-blog {
    .title-box {
      -ms-align-items: flex-end;
      align-items: flex-end;
      .section-title {
        padding-bottom: 0;
        padding-right: 10px;
      }
    }
    .slide-posts {
      padding-left: 15px;
    }
    .slider-item {
      .post {
        width: 250px 
      }
    } 
  }
}