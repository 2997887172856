.hero-slider {
  margin-top: 108px;
  position: relative;
  .swiper-container {
    overflow: hidden;
    height: 544px;
    &.swiper-container-initialized {
      height: auto;
    }
  }
  &.slick-dotted.slick-slider {
    margin-bottom: 0;
  }
  .img-logo-hero-banner {
    max-width: 107px;
    position: absolute;
    top: 180px;
    right: 15px;
  }
  .t-icon-btn-see-more {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 16px;
    background: #fddc00;
    margin-right: 10px;
  }
  .item {
    color: #fff;
    position: relative;
    z-index: 0;
    padding-top: 544 / 1350 * 100%;
    overflow: hidden;
    .bg {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      overflow: hidden;
      z-index: -1;
      img {
        max-width: none;
        // width: auto;
        // height: auto;
        // min-width: 100%;
        // min-height: 100%;
        // position: absolute;
        // right: 50%;
        // bottom: 50%;
        // @include transform(translate(50%, 50%));
        width: 100%;
        height: 100%;
        -o-object-fit: cover;
        object-fit: cover;
      }
    }

    &.has-overlay::after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(26, 26, 26, 0.6);
      content: "";
      z-index: -1;
    }
    .vt-slide-content {
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      margin: 0 auto;
      @media (min-width: 1680px) {
        max-width: (1170 / 1350 * 100%);
      }
      .title {
        color: #fff;
        font-weight: bold;
        font-size: 42px;
        line-height: 50px;
        margin-bottom: 32px;
        @include blockEllipsis(2);
        max-height: 100px;
      }
      .desc {
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 32px;
        @include blockEllipsis(3);
        max-height: 24 * 3 + px;
      }
      .content-box {
        height: 100%;
        -ms-flex-align: center;
        align-items: center;
      }
      .t-btn-hero,
      .wrap-btn-banner-1 {
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        align-items: center;
        font-size: 16px;
        line-height: 24px;
      }
      .t-btn-hero {
        color: #fff;
        @include transition(color 0.4s ease);
        font-size: 14px;
        line-height: 17px;
        & + .t-btn-hero {
          margin-left: 50px;
        }
      }
      .t-icon-btn-video {
        margin-right: 10px;
        @include flexbox;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        -webkit-border-radius: 50%;
        border-radius: 50%;
        line-height: 1;
        text-align: center;
        padding-left: 2px;
        background-color: $pink;

        svg {
          padding-left: 0px;
        }
      }
      .btn-click-to-scroll {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: #002245;
        display: inline-block;
        padding: 8px 24px;
        background: #fdd000;
        border-radius: 4px;
        text-transform: uppercase;
        margin-top: 8px;
      }
      @include for-lg {
        .title {
          font-size: 32px;
          line-height: 1.4;
        }
        .desc {
          font-size: 16px;
          line-height: 1.5;
        }
      }
      @include for-md {
        .title {
          margin-bottom: 24px;
        }
        .desc {
          margin-bottom: 24px;
        }
      }
    }
  }
  // .slick-dots {
  //   bottom: 0;
  //   top: 0;
  //   left: auto;
  //   right: 53px;
  //   width: auto;
  //   @include flexbox();
  //   flex-direction: column;
  //   justify-content: center;
  //   li {
  //     height: auto;
  //     position: relative;
  //     &:before,
  //     &:after {
  //       content: "";
  //       position: absolute;
  //       top: 50%;
  //       border-top: 1px solid #fff;
  //       @include transition(width 0.4s ease);
  //     }
  //     &:after {
  //       width: 0;
  //       right: 100%;
  //     }
  //     &:before {
  //       width: 0;
  //       left: 100%;
  //     }
  //     & + li {
  //       margin-top: 16px;
  //     }
  //     button {
  //       width: 10px;
  //       height: 10px;
  //       background-color: #fff;
  //       opacity: 0.2;
  //       -webkit-border-radius: 50%;
  //       border-radius: 50%;
  //       display: block;
  //       margin: 0 auto;

  //       &:before {
  //         content: none;
  //       }
  //     }
  //     &.slick-active {
  //       &:after {
  //         width: 15px;
  //       }
  //       &:before {
  //         width: 45px;
  //       }
  //       button {
  //         text-align: center;
  //         padding: 0;
  //         opacity: 1;
  //         font-size: 12px;
  //         line-height: 14px;
  //         background-color: transparent;
  //         width: 14px;
  //         height: 14px;
  //         color: #fff;
  //         &:before {
  //           opacity: 1;
  //         }
  //       }
  //     }
  //   }
  // }
  .slider-wrapper {
    position: relative;
  }
  .swiper-pagination-bullets {
    bottom: 0;
    top: 0;
    left: auto;
    right: 53px;
    width: auto;
    @include flexbox();
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    .swiper-pagination-bullet {
      width: 10px;
      height: 10px;
      background-color: #fff;
      opacity: 0.6;
      -webkit-border-radius: 50%;
      border-radius: 50%;
      display: block;
      position: relative;
      font-size: 0;
      line-height: 0;
      margin: 0;
      &:before,
      &:after {
        content: "";
        position: absolute;
        top: 50%;
        border-top: 1px solid #fff;
        @include transition(width 0.4s ease);
      }
      &:after {
        width: 0;
        right: 16px;
      }
      &:before {
        width: 0;
        left: 16px;
      }
      & + .swiper-pagination-bullet {
        margin-top: 16px;
      }
      &:focus,
      &:active {
        outline: none;
      }
      &.swiper-pagination-bullet-active {
        &:after {
          width: 15px;
        }
        &:before {
          width: 45px;
        }

        text-align: center;
        padding: 0;
        opacity: 1;
        font-size: 12px;
        line-height: 14px;
        background-color: transparent;
        width: 14px;
        height: 14px;
        color: #fff;
        &:before {
          opacity: 1;
        }
      }
    }
  }
}
@include for-lg {
  .hero-slider {
    margin-top: 64px;
    .img-logo-hero-banner {
      top: 70px;
      right: 16px;
      max-width: 73px;
    }
  }
}

@include for-sm {
  .hero-slider {
    margin-top: 54px;
    .item {
      padding-top: (2397 / 1417 * 100%);
    }
    .item .vt-slide-content {
      text-align: center;
      padding: 60px 18px;
      padding: 10vw (18 / 385 * 100 + vw);
    }
    .item .vt-slide-content .title {
      font-size: 28px;
      line-height: 32px;
      margin-bottom: 24px;
      margin-bottom: (24 / 345 * 100%);
      max-height: 64px;
    }
    .item .vt-slide-content .desc {
      margin-bottom: 24px;
      margin-bottom: (24 / 345 * 100%);
      font-size: 14px;
      line-height: 20px;
      max-height: 60px;
    }
    .item .vt-slide-content .t-btn-hero + .t-btn-hero {
      margin-left: 0;
    }
    .item .vt-slide-content .t-icon-btn-video {
      width: 24px;
      height: 24px;

      -webkit-border-radius: 12px;
      border-radius: 12px;
      margin-right: 4px;

      svg {
        height: 10px;
      }
    }

    .item .vt-slide-content .t-btn-hero + .t-btn-hero {
      margin-left: 0;
      margin-top: 8px;
    }

    .wrap-btn-banner-1 {
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      flex-direction: column;
    }
    .t-icon-btn-see-more {
      width: 24px;
      height: 24px;
      margin-right: 4px;
      svg {
        height: 9px;
      }
    }
    // .slick-dots {
    //   top: auto;
    //   bottom: 17px;
    //   left: 0;
    //   right: 0;
    //   flex-direction: row;
    //   justify-content: center;

    //   li {
    //     width: auto;
    //     & + li {
    //       margin-top: 0;
    //       margin-left: 8px;
    //     }
    //     &:before,
    //     &:after {
    //       content: none;
    //     }
    //     &.slick-active {
    //       button {
    //         font-size: 0;
    //         width: 10px;
    //         height: 10px;
    //         background-color: $main;
    //         &:before {
    //           content: none;
    //         }
    //       }
    //     }
    //   }
    // }
    .swiper-pagination-bullets {
      top: auto;
      bottom: 17px;
      left: 0;
      right: 0;
      flex-direction: row;
      justify-content: center;

      .swiper-pagination-bullet {
        width: 10px;
        height: 10px;
        font-size: 0;
        & + .swiper-pagination-bullet {
          margin-top: 0;
          margin-left: 8px;
        }
        &:before,
        &:after {
          content: none;
        }
        &.swiper-pagination-bullet-active {
          background-color: $main;
          font-size: 0;
          width: 10px;
          height: 10px;
        }
      }
    }
  }
}
