.home-brands {
  background-color: #fff;
  padding: 32px 0 64px;
  .slick-slider .slick-track {
    min-width: auto;
  }
  .brands {
    .section-title {
      margin-bottom: 32px;
      text-align: center;
      color: $dark-blue;
    }
    .list-brands {
      .swiper-slide {
        width: auto;
        .image {
          padding: 0 15px;
          height: 40px;
          @include flexbox();
          -ms-align-items: center;
          -ms-justify-content: center;
          align-items: center;
          justify-content: center;
        }
      }
      img {
        max-width: 100%;
        display: block;
        max-height: 40px;
        margin: 0 auto;
      }
    }
  }
}

@mixin home-brands-sm {
  .home-brands {
    padding-bottom: 48px;
    padding-top: 24px;
    .brands {
      .section-title {
        width: 271px;
        margin-left: auto;
        margin-right: auto;
        max-width: 100%;
        margin-bottom: 26px;
      }
      .list-brands {
        li {
          padding-right: 12px;
          padding-left: 12px;
        }
      }
    }
  }
}
