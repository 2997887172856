@import "./suggest-solution/suggest-product";
@import 'src/assets/sass/includes/master/mixins';

.home-suggest-solution {
  position: relative;
  overflow: hidden;
  z-index: 0;
  background-color: #fff;
  //padding-top: 804px;
  .button-box {
    clear: both;
    width: 100%;
    text-align: center;
    .btn-action {
      -ms-transition: background-color 0.4s ease;
      -o-transition: background-color 0.4s ease;
      -moz-transition: background-color 0.4s ease;
      -webkit-transition: background-color 0.4s ease;
      transition: background-color 0.4s ease;
      &:hover {
        background-color: #ffc107;
      }
    }
  }
  .bg {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    overflow: hidden;
    z-index: -1;
    img {
      max-width: none;
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover;
    }
  }
  &.has-overlay::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, rgba(0, 9, 18, 0.7) 0%, rgba(0, 9, 18, 0) 100%);
    content: "";
    z-index: -1;
  }
  .suggestion-content {
    height: 100%;
    position: relative;
    //top: 0;
    //left: 0;
    //right: 0;
    margin: 0 auto;
    padding: 0 63px 138px;
    @include for-lg {
      padding: 0 40px 138px;
    }
    //@media (min-width: 1680px) {
    //  max-width: (1210 / 1350 * 100%);
    //}
    .content-box {
      height: 100%;
      -ms-flex-align: center;
      align-items: center;
      padding-top: 149px;
      @include flexbox;
      @include align-items(flex-start);
      @include justify-content(center);
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      column-gap: 24px;
      .row {
        margin: 0 0 40px 0;
      }
      .form-group {
        margin-bottom: 0;
      }
      .section-title {
        color: #fff;
        font-size: 28px;
        font-weight: 500;
        line-height: 48px;
        margin-bottom: 0;
        -ms-flex: 0 0 361px;
        flex: 0 0 361px;
      }
      .form-item {
        //padding-top: 10px;
        -ms-flex: 0 0 399px;
        flex: 0 0 399px;
        .select2-container {
          .select2-selection--single {
            background: rgba(0, 0, 0, 0.15);
            border: 1.5px solid rgba(255, 255, 255, 0.6);
            border-radius: 4px;
          }
          .select2-selection__rendered {
            font-size: 20px;
            line-height: 56px;
            font-weight: 400;
            color: #FFFFFF;
            padding: 0 24px;
          }
          .select2-selection__arrow {
            right: 24px;
          }
        }
      }

      .slider-content {
        width: 100%;
      }
      .slider-wrapper {
        @include transition(all 0.3s ease);
        max-width: 880px;
        margin: 90px 0 0 0;
        &:has(.swiper-slide:hover) {
        }
        .swiper-pagination {
          //bottom: -160px;
          //left: 24%;
          @include flexbox;
          -webkit-box-align: center;
          -webkit-align-items: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: center;
          -webkit-justify-content: center;
          -ms-flex-pack: center;
          justify-content: center;
          .swiper-pagination-bullet {
            display: block;
            opacity: 1;
            background-color: #c4c4c4;
            @include transition(all 0.4s ease);
            &.swiper-pagination-bullet-active {
              background-color: #FDD000;
            }
          }
        }

        .slider-controls {
          .btn-slider {
            &--prev {
              top: 126px;
              left: -75px;
            }
            &--next {
              top: 126px;
              right: -75px;
            }
            //@media (max-width: 1229.98px) {
            //  &--next {
            //    right: -207px;
            //  }
            //}
            //@include for-lg {
            //  &--prev {
            //    left: -51px;
            //  }
            //  &--next {
            //    right: -51px;
            //  }
            //}
            //@include for-md {
            //  &--next {
            //    right: -103px;
            //  }
            //}
          }
        }

      }
      .swiper-wrapper {
        .swiper-slide {
          //margin: 0 15px;
          @include transition(all 0.3s ease);


          @include transform(scale(0.78));
          &:hover {
            //margin: 0 15px;
            @include transform(scale(1));
            .button-box {
              .btn-action {
                background: #fdd000;
                border: none;
                padding: 11px 0;
              }
            }
          }
        }
      }

      a.view-all {
        text-decoration: underline;
        &:hover {
          color: #FDD000;
        }
        font-size: 16px;
        font-weight: 500;
        line-height: 22px;
        color: #FFFFFF;

      }
    }
  }


}
@mixin home-suggest-solution-md {
  .home-suggest-solution {
    .suggestion-content {
      .content-box {
        .slider-wrapper {
          max-width: 590px;
          margin-top: 45px;
        }
      }
    }
  }
}

@mixin home-suggest-solution-sm {
  .home-suggest-solution {
    //padding-top: max(579 / 375 * 100%, 579px);
    .suggestion-content {
      padding: 0 0 12px;
      .content-box {
        padding-top: 40px;
        .solution {
          width: 100%;
        }
        .section-title {
          font-size: 18px;
          font-weight: 700;
          line-height: 26px;
          text-align: center;
        }
        .section-title {
          font-size: 18px;
          font-weight: 700;
          line-height: 26px;
        }
        .form-item {
          padding: 4px 50px;
          width: auto;
          .select2-container {
            .select2-selection__rendered {
              font-size: 24px;
              line-height: 35px;
              font-weight: 700;
              color: #FFFFFF;
              padding: 6px 12px;
            }
          }
        }
        .slider-wrapper {
          margin: 14px 0 0;
          max-width: 100%;
          .swiper-pagination {
            left: auto;
            bottom: -25px;
            @include flexbox;
            -webkit-box-align: center;
            -webkit-align-items: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: center;
            -webkit-justify-content: center;
            -ms-flex-pack: center;
            justify-content: center;
          }

          .slider-controls {
            //.btn-slider {
            //  &--prev {
            //    left: -71px;
            //  }
            //  &--next {
            //    right: -71px;
            //  }
            //}
          }

        }
        .swiper-wrapper {
          .swiper-slide {
            @include transition(all 0.3s ease);


            @include transform(none);
            &:hover {
              @include transform(none);
            }
          }
          .product-item {
            padding: 0 calc(16 / 375 * 100vw );
          }
        }
        .row {
          margin: 0 0 38px 0;
        }
        a.view-all {
          @include flexbox;
          flex-direction: column;
          text-align: center;
          font-size: 14px;
          font-weight: 500;
          line-height: 22px;

        }

      }
    }
  }
}
