.view-videos-multi-comfort .js-pager__items,
.video-load-more {
  background-color: #fff;
}
.home-video {
  background-color: #fff;
  padding-bottom: 2px;
  padding-top: 32px;
  
  
  .section-title {
    margin-bottom: 32px;
  }    
  
  .video-item {
    margin-bottom: 30px;
    padding: 0 15px;
    -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;
    .video-content {
      position: relative;
      height: 324px;
      .bg-video {
        height: 100%;
        .bg {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          overflow: hidden;
          img {
            max-width: none;
            width: auto;
            height: auto;
            min-width: 100%;
            min-height: 100%;
            position: absolute;
            right: 50%;
            bottom: 50%;
            @include transform(translate(50%, 50%));
          }
        }
      }
      .btn-play {
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -35px 0 0 -35px;
        display: inline-flex;
        display: -webkit-inline-flex;
        display: -moz-inline-flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        border-radius: 100px;
        border: 2px solid #fff;
        width: 70px;
        height: 70px;
        transition: all .5s;
        svg {
          width: 24px;
          height: auto;
          margin-right: -8px;
          polygon {
            fill: #fff;
          }
        }
        &:hover {
          background-color: rgba(255,255,255,.5);
          border-color: transparent;
        }
      }
      .click-to-play {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

      }
      .info {
        position: absolute;
        bottom: 40px;
        left: 40px;
        right: 40px;
        color: #fff;
        .numb {
          font-size: 20px;
          line-height: 1.5;
          margin-bottom: 0;
        }
        .title {
          margin-bottom: 0;
          font-size: 28px;
          line-height: (32/ 28);
          font-weight: bold;
          @include blockEllipsis(2);
        }
      }
    }
    &:hover {
      .btn-play {
        background-color: rgba(255, 255, 255, 0.5);
        border-color: transparent;
      }
    }
    &:first-child {
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
      .video-content {
        height: 504px;
      }
    }
  }
}

@mixin home-video-sm {
  .home-video {
    padding-bottom: 6px;
    padding-top: 32px;
    .section-title {
      font-size: 28px;
      line-height: 1.14;
      margin-bottom: 24px;
    }
    .video-list {
      margin: 0 -5px;
    }
    .video-item {
      padding: 0 5px;
      margin-bottom: 10px;
      .video-content {
        height: 130px;
        .btn-play {
          width: 40px;
          height: 40px;
          margin: -20px 0 0 -20px;
        }
        .info {
          bottom: 8px;
          left: 8px;
          right: 8px;
          .numb {
            font-size: 10px;
          }
          .title {
            font-size: 14px;
          }
        }
      }
      &:first-child {
        .video-content {
          height: 230px;
        }
      }
    }
  }
}
@mixin home-video-xs {
  .home-video {
    .video-item {
      &:first-child {
        .video-content {
          .btn-play {
            width: 35px;
            height: 35px;
            svg {
              width: 12px;
            }
          }
        }

      }
      .video-content {
        .btn-play {
          width: 23px;
          height: 23px;
          margin: -11.5px 0 0 -11.5px;
          border-width: 1px;
          svg {
            width: 8px;
            height: auto;
            margin-right: -2px;
          }
        } 
      }
    }
  }
}