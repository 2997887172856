.form-item {
  .form-control {
    border: none;
    font-size: 15 / 16 + rem;
    line-height: (20 / 16);
    padding: 15px 23px;
    width: 100%;
    outline: none;
    border: 1px solid transparent;
    background-color: #fff;
    letter-spacing: -0.02em;
    display: block;
    color: #4e4e4e;
    &:disabled {
      color: #A2A2A2;
      &:hover {
        cursor: not-allowed;
      }
    }
    &:focus,
    &:hover {
      outline: none;
    }
    @include placeholder {
      color: #a2a2a2;
    }
    &--rounded {
      -webkit-border-radius: 25px;
      border-radius: 25px;
    }
    &.bg-gray {
      background-color: #f6f6f6;
      @include placeholder {
        color: #a2a2a2;
      }
    }
    
  }
  &.is-invalid .form-control {
    border-color: red;
  }
  .error-text {
    color: red;
    font-size: 12 / 16 + rem;
    line-height: 1.43;
    display: block;
    margin-top: 4px;
  }
  select.form-control {
    color: #4e4e4e;
    cursor: pointer;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-repeat: no-repeat;
    background-position: right 23px center;
    padding-right: 50px;
    background-image: url('data:image/svg+xml, <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.766602 1.27954L5.7666 6.23832L10.7666 1.27954" stroke="rgb(161,161,161)" stroke-width="2"/></svg>');
    &--rounded {
      padding-right: 50px;
    }
  }

  &--checkbox {
    position: relative;
    input {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      visibility: hidden;
    }
    label {
      cursor: pointer;
      margin-bottom: 0;
      display: block;
      position: relative;
      font-weight: normal;
      font-size: 15px;
      line-height: 28px;
      letter-spacing: -0.02em;
      color: #4e4e4e;
      span {
        padding-left: 28px;
        display: block;
        &:before {
          content: "";
          position: absolute;
          top: 50%;
          left: 0;
          margin-top: -9px;
          width: 18px;
          height: 18px;
          border: 2px solid #4e4e4e;
          -webkit-border-radius: 2px;
          border-radius: 2px;
        }
      }
    }
    input:checked + span {
      color: #00529c;
      &:before {
        border-color: #007BFFFF;
        background-color: #007BFFFF;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url('data:image/svg+xml,<svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M11.3281 1.30858C11.8607 1.84112 11.8607 2.70453 11.3281 3.23707L5.70308 8.86211C5.17055 9.39464 4.30714 9.39464 3.7746 8.86211L1.21776 6.30527C0.685225 5.77273 0.685225 4.90932 1.21776 4.37679C1.7503 3.84425 2.61371 3.84425 3.14625 4.37679L4.73884 5.96938L9.39964 1.30858C9.93217 0.776045 10.7956 0.776045 11.3281 1.30858Z" fill="rgb(255, 255, 255)"/></svg>');
      }
    }
  }
  .select2-container--open .select2-selection__arrow {
    @include transform(translateY(-50%) scaleY(-1) !important);
  }
  .select2.select2-container {
    max-width: 100%;
    min-width: 100%;
    .select2-selection__arrow {
      @include transition(all 0.2s ease);
      top: 50%;
      transform: translateY(-50%);
      right: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      b {
        background-image: url('data:image/svg+xml, <svg width="20" height="10" viewBox="0 0 20 10" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M18 1.61524L10 8.2819L2 1.61523" stroke="white" stroke-width="2.66667" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/> </svg>');
        background-color: transparent;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        border: none !important;
        height: 12px !important;
        width: 30px !important;
        margin: auto !important;
        top: auto !important;
        left: auto !important;
      }
    }
    .select2-selection--single {
      height: auto;
      border: none;
      border-radius: 8px;
      border: 1px solid transparent;
      padding: 0;
      .select2-selection__rendered {
        text-overflow: ellipsis;
        font-size: 15px;
        line-height: 28px;
        letter-spacing: -0.02em;
        color: #4e4e4e;
        padding: 11px 24px;
        padding-right: 45px;
        background-repeat: no-repeat;
        background-position: right 23px center;
        min-height: 50px;
        //background-image: url('data:image/svg+xml, <svg width="9" height="7" viewBox="0 0 9 7" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 1.40918L4.5 5.40918L8 1.40918" stroke="rgb(161,161,161)" stroke-width="2"/></svg>');
      }
    }
    .select2-selection__placeholder {
      color: #A2A2A2
    }
    &.select2-container--open.select2-container--above .select2-selection,
    &.select2-container--open.select2-container--below .select2-selection {
      border-radius: 8px;
    }
  }
  &.is-invalid .select2.select2-container {
    .select2-selection {
      border-color: #DC3545FF;
    }
  }
}
.dropdown-control {
  position: relative;
  .btn-toggle {
    color: #A2A2A2;
    height: 50px;
    border-radius: 4px;
    background-color: #fff;
    width: 100%;
    text-align: left;
    padding: 10px 24px;
    background-repeat: no-repeat;
    background-position: right 23px center;
    padding-right: 50px;
    background-image: url('data:image/svg+xml, <svg width="9" height="7" viewBox="0 0 9 7" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 1.40918L4.5 5.40918L8 1.40918" stroke="rgb(161,161,161)" stroke-width="2"/></svg>');
    box-shadow: 7px 7px 8px rgba(0, 0, 0, 0.03);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &--rounded {
      border-radius: 25px;
    }
  }

  .dropdown-list {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    margin-top: 5px;
    border-radius: 10px;
    padding: 24px;
    padding-right: 10px;
    z-index: 30;
    background-color: #fff;
    box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.05);
    .form-item {
      margin-bottom: 0;
      & + .form-item {
        margin-top: 12px;
      }
    }
    .wrapper {
      padding-right: 8px;
      max-height: 352px;
      @include scrollbar-vertical(4px, rgba(#EDEDED, 0.3), #C6CCD3)
    }
  }
}
.select2-container,
.select2-container.select2-container--open {
  .select2-dropdown {
    border: 1px solid rgba(255, 255, 255, 1);
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 0.8);
    //padding: 10px 10px 10px 16px;
    box-shadow: 0px 10px 24px 0px rgba(29, 30, 32, 0.06);
    &.select2-dropdown--below {
      margin-top: 8px;
    }
    &.select2-dropdown--above {
      margin-bottom: 8px;
    }
  }
  .select2-results {
    .select2-results__options {
      padding: 0;
      max-height: 344px;
      @include scrollbar-vertical(0px, transparent, #c6ccd3, scroll);
      .select2-results__option {
        padding: 0 16px;
        font-weight: 400;
        font-size: 20px;
        line-height: 64px;
        letter-spacing: -0.02em;
        color: #1A1A1A;
        //@include for-sm {
        //  font-weight: 400;
        //  font-size: 24px;
        //  line-height: 35px;
        //}
        & + .select2-results__option {
          padding-top: 6px;
          @include transition(color 0.4s ease);
        }
        &.select2-results__option--selected {
          color: #00529c;
          background-color: transparent;
        }
        &.select2-results__option--highlighted.select2-results__option--selectable,
        &:hover {
          background: rgba(253, 208, 0, 0.5);
          -webkit-border-radius: 8px;
          border-radius: 8px;
        }
      }
    }
  }
}
.form-notice {
  font-weight: 500;
  font-size: 15px;
  line-height: 28px;
  letter-spacing: -0.02em;
  p {
    font-family: "Helvetica Neue";
    @include flexbox;
    margin-bottom: 0;
  }
  .btn {
    margin-top: 24px;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    min-width: 180px;
    font-weight: bold;
    padding: 14px 20px;
  }
  svg {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    margin-right: 12px;
  }
  
  &.error {
    color: #ff0000;
    margin-bottom: 24px;
    svg {
      margin-top: 2px;
    }
  }
  &.success {
    text-align: center;
    max-width: 439px;
    margin: 0 auto;
    p {
      display: block;
    }
    svg {
      margin-right: 0;
      margin-bottom: 16px;
      width: 42px;
      height: auto;
    }
    @include for-sm {
      svg {
        margin-bottom: 14px;
      }
    }
  }
  @include for-sm {
    .btn {
      margin-top: 1rem;
      padding: 12px;
      min-width: 106px;
    }
  }
}