.home-features {
  background-color: #fff;
  padding-top: 32px;
  padding-bottom: 32px;

  .title-n-link {
    @include flexbox();
    flex-wrap: nowrap;
    -ms-align-items: center;
    align-items: center;
    -ms-justify-content: space-between;
    justify-content: space-between;
    margin-bottom: 40px;

    .section-title {
      margin-bottom: 0;
      padding-right: 10px;
    }

    .btn-viewmore {
      flex-shrink: 0;
    }
  }

  // .slider-item {
  //   height: 660px;
  //   position: relative;

  //   .bg {
  //     position: absolute;
  //     top: 0;
  //     left: 0;
  //     right: 0;
  //     bottom: 0;
  //     overflow: hidden;
  //     z-index: -1;

  //     img {
  //       max-width: none;
  //       width: auto;
  //       height: auto;
  //       min-width: 100%;
  //       min-height: 100%;
  //       position: absolute;
  //       right: 50%;
  //       bottom: 50%;
  //       @include transform(translate(50%, 50%));
  //     }
  //   }

  //   &.has-overlay:after {
  //     content: "";
  //     position: absolute;
  //     top: 0;
  //     left: 0;
  //     right: 0;
  //     bottom: 0;
  //     background-color: #1a1a1a;
  //     opacity: 0.6;
  //   }

  //   .content-box,
  //   .slider-container {
  //     height: 100%;
  //   }

  //   .content-box {
  //     position: relative;
  //     z-index: 10;
  //     color: #fff;
  //     -ms-align-items: center;
  //     align-items: center;

  //     .content {
  //       .title {
  //         font-weight: 600;
  //         font-size: 42px;
  //         line-height: 1.2;
  //         margin-bottom: 24px;



  //         transition-delay: 400ms;
  //       }

  //       .desc {
  //         font-size: 16px;
  //         line-height: 24px;
  //         margin-bottom: 28px;



  //         transition-delay: 400ms;
  //       }

  //       .button-box {
  //         @include flexbox();
  //         align-items: center;

  //         .btn-slider {
  //           @include flexbox();
  //           -webkit-box-align: center;
  //           -webkit-align-items: center;
  //           -ms-flex-align: center;
  //           align-items: center;
  //           @include transition(color 0.4s ease);
  //           color: #fff;

  //           &:hover {
  //             color: $main;
  //           }

  //           &+.btn-slider {
  //             margin-left: 50px;
  //           }

  //           span {
  //             width: 36px;
  //             height: 36px;
  //             flex-shrink: 0;
  //             @include flexbox();
  //             align-items: center;
  //             justify-content: center;
  //             @include border-radius(50%);
  //             margin-right: 15px;
  //             padding-left: 2px;

  //             &.icon-see-more {
  //               background-color: $main;
  //             }

  //             &.icon-video {
  //               background-color: $pink;
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }
  // }

  // .swiper-pagination-bullets {
  //   bottom: 0;
  //   top: 0;
  //   left: auto;
  //   right: 53px;
  //   width: auto;
  //   @include flexbox();
  //   flex-direction: column;
  //   -webkit-box-pack: center;
  //   -webkit-justify-content: center;
  //   -ms-flex-pack: center;
  //   justify-content: center;
  //   -webkit-box-align: center;
  //   -webkit-align-items: center;
  //   -ms-flex-align: center;
  //   align-items: center;

  //   .swiper-pagination-bullet {
  //     width: 10px;
  //     height: 10px;
  //     background-color: #fff;
  //     opacity: 0.6;
  //     -webkit-border-radius: 50%;
  //     border-radius: 50%;
  //     display: block;
  //     position: relative;
  //     font-size: 0;
  //     line-height: 0;
  //     margin: 0;

  //     &:before,
  //     &:after {
  //       content: "";
  //       position: absolute;
  //       top: 50%;
  //       border-top: 1px solid #fff;
  //       @include transition(width 0.4s ease);
  //     }

  //     &:after {
  //       width: 0;
  //       right: 16px;
  //     }

  //     &:before {
  //       width: 0;
  //       left: 16px;
  //     }

  //     &+.swiper-pagination-bullet {
  //       margin-top: 16px;
  //     }

  //     &:focus,
  //     &:active {
  //       outline: none;
  //     }

  //     &.swiper-pagination-bullet-active {
  //       &:after {
  //         width: 15px;
  //       }

  //       &:before {
  //         width: 45px;
  //       }

  //       text-align: center;
  //       padding: 0;
  //       opacity: 1;
  //       font-size: 12px;
  //       line-height: 14px;
  //       background-color: transparent;
  //       width: 14px;
  //       height: 14px;
  //       color: #fff;

  //       &:before {
  //         opacity: 1;
  //       }
  //     }
  //   }
  // }
  .swiper-pagination-bullet {
    &:focus {
      outline: none;
    }
  }
}

// custom

.home-features {

  .project-item {
    padding: 4px;
    background: #ffffff;
    border: 1px solid rgba(152, 152, 154, 0.2);
    border-radius: 4px;

    .img {
      position: relative;

      &__main {
        width: 100%;
        height: 100%;
        -o-object-fit: cover;
        object-fit: cover;
      }

      .options {
        position: absolute;
        bottom: 11px;
        right: 10px;
        @include flexbox;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;

        .btn-view-gallery {
          -ms-flex-negative: 0;
          flex-shrink: 0;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          text-align: center;
          color: #000000;
          padding: 4px 16px;
          border-radius: 14px;
          border: none;
          background-color: rgba(242, 242, 242, 0.6);
          backdrop-filter: blur(10px);
          @include transition(background-color 0.4s ease, color 0.4s ease);

          &:hover {
            background-color: #002245;
            color: #fff;
          }
        }

        .share {
          margin-bottom: 0;
          @include flexbox;
          list-style: none;
          padding-left: 20px;

          li {
            &+li {
              margin-left: 10px;
            }
          }
        }
      }
    }

    .info {
      padding: 16px 12px 12px;
    }

    .title {
      font-weight: bold;
      font-size: 24px;
      line-height: 28px;
      color: #161616;
      margin-bottom: 0;
    }

    .address {
      text-transform: uppercase;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: #000000;
      display: inline-block;
      margin-bottom: 4px;
    }

    .cat {
      margin-bottom: 4px;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #ffffff;
      padding-left: 15px;
      margin-left: 10px;
      position: relative;

      &:after {
        content: "";
        position: absolute;
        top: 7.5px;
        left: 0;
        width: 5px;
        height: 5px;
        background-color: #fff;
        border-radius: 50%;
      }
    }

    .list-info {
      padding-top: 10px;
      list-style: none;
      padding-left: 0;
      margin-bottom: 10px;
      color: #707781;
      font-size: 14px;
      line-height: 20px;
      @include flexbox;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      border-bottom: 1px solid #f2f2f2;
      padding-bottom: 10px;

      li {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
      }

      span {
        font-weight: 500;
        color: #161616;
      }
    }

    .feature {
      &__title {
        font-weight: bold;
        font-size: 16px;
        line-height: 22px;
        color: #161616;
        margin-bottom: 5px;
      }

      &__list {
        font-size: 14px;
        line-height: 20px;
        list-style: none;
        padding-left: 0;
        margin-bottom: 4px;
        color: #333333;
        @include flexbox;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;

        li {
          margin-bottom: 6px;
          -ms-flex: 0 0 50%;
          flex: 0 0 50%;
          max-width: 50%;
          @include flexbox;

          -webkit-box-align: center;
          -webkit-align-items: center;
          -ms-flex-align: center;
          align-items: center;

          .icon {
            width: 32px;
            height: 32px;
            @include flexbox;
            -ms-flex-negative: 0;
            flex-shrink: 0;
            margin-right: 8px;
            -webkit-box-align: center;
            -webkit-align-items: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: center;
            -webkit-justify-content: center;
            -ms-flex-pack: center;
            justify-content: center;

            img {
              display: block;
            }
          }

          span {
            display: inline-block;
          }

          .text {
            padding-right: 5px;
            display: inline-block;
            color: inherit;
            @include transition(color 0.4s ease);

            &:hover {
              color: #fdd000;
            }
          }
        }
      }
    }

    .bottom {
      .row {
        margin: 0 -8px;

        [class*="col-"] {
          padding: 0 8px;
        }
      }

      &__title {
        font-weight: bold;
        font-size: 16px;
        line-height: 22px;
        color: #161616;
        margin-bottom: 5px;
      }

      &__list {
        margin-bottom: 0;
        padding-left: 0;
        font-size: 16px;
        line-height: 22px;
        color: #333333;
        list-style: none;

        li {
          &:before {
            content: "-";
            margin-right: 4px;
            display: inline-block;
          }

          a {
            color: inherit;
            @include transition(color 0.4s ease);

            &:hover {
              color: #fdd000
            }
          }
        }
      }
    }
  }

  .feature-project {
    padding-bottom: 30px;
    width: 100%;

    .swiper-slide {
      padding: 0 15px;
    }

    .project-item {
      padding: 0;
      background-color: transparent;
      @include flexbox;

      .img {
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        height: 490px;
      }

      .info {
        background-color: #002245;
        color: #fff;
        width: 470px;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        padding: 35px;
        height: 490px;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;

        &::-webkit-scrollbar {
          width: 4px;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 2px;
          background-color: #fdd000;
        }

        &::-webkit-scrollbar-track {
          border-radius: 2px;
        }
      }

      .title {
        color: #fff;
        @include blockEllipsis(2);
      }

      .address {
        font-weight: normal;
        color: #fff;
        font-size: 14px;
        line-height: 20px;
        opacity: 0.8;
      }

      .list-info {
        font-size: 12px;
        line-height: 16px;
        color: #fff;
        padding-bottom: 16px;
        margin-bottom: 20px;

        span {
          display: block;
          color: #fff;
        }

        li {
          padding-left: 14px;
          padding-right: 5px;
          position: relative;
          margin-bottom: 4px;

          &:after {
            content: "";
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background: #fdd000;
            opacity: 0.8;
            position: absolute;
            top: 5px;
            left: 0;
          }
        }
      }

      .feature {
        &__title {
          color: #fff;
          margin-bottom: 8px;
        }

        &__list {
          color: #f2f2f2;

          li {
            .icon {
              margin-right: 10px;
            }
          }
        }
      }

      .bottom {
        &__title {
          color: #fff;
        }

        &__list {
          color: #f2f2f2;
          font-size: 14px;
          line-height: 20px;

          li:before {
            content: none;
          }
        }
      }
    }

    .swiper-wrapper {
      margin-bottom: 48px;
    }

    .swiper-pagination {
      bottom: 0;
      @include flexbox;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;

      .swiper-pagination-bullet {
        display: block;
        opacity: 1;
        background-color: #c4c4c4;
        @include transition(all 0.4s ease);

        &.swiper-pagination-bullet-active {
          background-color: #161616;
        }
      }
    }
  }
}
@media (max-width: 1199px) {
  .home-features .feature-project .project-item .info {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    width: auto;
    max-width: 50%;
  }
}
@media (max-width: 767px) {
  .home-features {
    
    .feature-project {
      .slider-wrapper {
        padding-bottom: 28px;
      }
      .project-item {
        display: block;
        .img {
          height: 200px;
        }
        .info {
          max-width: none;
          padding: 20px;
          height: 479px;
          .title {
            font-weight: bold;
            font-size: 18px;
            line-height: 24px;
            color: #ffffff;
          }
          .list-info {
            padding-top: 14px;
            padding-bottom: 14px;
            margin-bottom: 14px;
          }
        }
      }
    }
  }
}

@mixin home-features-xs {
  .home-features {
    padding-top: 20px;

    .title-n-link {
      -ms-align-items: flex-end;
      align-items: flex-end;
      margin-bottom: 24px;

      .section-title {
        font-size: 28px;
        line-height: 1.14;
      }
    }

    // .slider-item {
    //   height: 630px;
    // }

    // .slider-item .content-box {
    //   -ms-align-items: flex-end;
    //   align-items: flex-end;
    //   padding-bottom: 64px;

    //   .content {
    //     .title {
    //       font-size: 28px;
    //       margin-bottom: 16px;
    //     }

    //     .desc {
    //       margin-bottom: 24px;
    //     }

    //     .button-box {
    //       .btn-slider {
    //         font-weight: 300;
    //       }
    //     }
    //   }
    // }

    // .swiper-pagination-bullets {
    //   top: auto;
    //   bottom: 17px;
    //   left: 0;
    //   right: 0;
    //   flex-direction: row;
    //   justify-content: center;

    //   .swiper-pagination-bullet {
    //     width: 10px;
    //     height: 10px;
    //     font-size: 0;

    //     &+.swiper-pagination-bullet {
    //       margin-top: 0;
    //       margin-left: 8px;
    //     }

    //     &:before,
    //     &:after {
    //       content: none;
    //     }

    //     &.swiper-pagination-bullet-active {
    //       background-color: $main;
    //       font-size: 0;
    //       width: 10px;
    //       height: 10px;
    //     }
    //   }
    // }

    // .slider-item .content-box .content .button-box .btn-slider {
    //   font-size: 14px;

    //   &+.btn-slider {
    //     margin-left: 20px;

    //   }

    //   span.icon-video {
    //     width: 50px;
    //     @include border-radius(15px);
    //     height: 30px;
    //     background-color: #FF0000;
    //   }
    // }
  }
}