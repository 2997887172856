.home-find {
  position: relative;
  .bg {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    z-index: -1;
    img {
      max-width: none;
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover;
    }
    &:after {
      content: "";
      position: absolute;
      top:0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
  .bg2 {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    z-index: -1;

    img {
      max-width: none;
      width: 100%;
      height: calc(100% + 62px);
      object-fit: cover;
      object-position: center -62px;
    }

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }

  .button-box {
    .btn-action {
      background: #fdd000;
      box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.05);
      box-sizing: border-box;
      border-radius: 27px;
      font-weight: bold;
      font-size: 14px;
      line-height: 17px;
      text-align: center;
      color: #002245;
      padding: 16px 12px;
      width: 182px;
      height: 50px;
      max-width: 100%;
      margin: 32px auto 0 auto;
      display: block;
      cursor: pointer;
      -ms-transition: background-color 0.4s ease;
      -o-transition: background-color 0.4s ease;
      -moz-transition: background-color 0.4s ease;
      -webkit-transition: background-color 0.4s ease;
      transition: background-color 0.4s ease;
      &:hover {
        background-color: #ffc107;
      }
    }
  }

  .webform-ajax-form-wrapper {
    form {
      .form-item {
        margin-bottom: 16px;

        textarea,
        input,
        select {
          display: block;
          width: 100%;
          border: 1px solid rgba(0, 0, 0, 0.2);
          -webkit-border-radius: 4px;
          border-radius: 4px;
          padding: 0 16px;
          outline: none;
          font-size: 16px;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          &:hover,
          &:focus {
            outline: none;
            -webkit-box-shadow: none;
            box-shadow: none;
          }
          @include placeholder {
            color: #1a1a1a;
            font-size: 14px;
            opacity: 0.3;
          }
        }
        select,
        input {
          height: 48px;
        }
        select {
          background-image: url("../images/icons/arrow-down.svg");
          background-repeat: no-repeat;
          background-position: right 16px center;
          padding-right: 40px;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          background-color: #fff;
        }
        textarea {
          padding: 12px 16px;
          resize: none;
          height: 150px;
        }
        select:disabled,
        textarea:disabled,
        input:disabled {
          cursor: not-allowed;
          background-color: #e8e8e8;
          color: #969696;
        }
      }

      input[type="submit"] {
        background: #fdd000;
        box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.05);
        border: none;
        box-sizing: border-box;
        border-radius: 27px;
        font-weight: bold;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        color: #002245;
        padding: 16px 12px;
        width: 182px;
        max-width: 100%;
        margin: 32px auto 0 auto;
        display: block;
        cursor: pointer;
        -ms-transition: background-color 0.4s ease;
        -o-transition: background-color 0.4s ease;
        -moz-transition: background-color 0.4s ease;
        -webkit-transition: background-color 0.4s ease;
        transition: background-color 0.4s ease;
        &:hover {
          background-color: #ffc107;
        }
      }
    }
  }

  .title-wrapper {
    width: 470px;
    max-width: 100%;
  }
  .section-title {
    font-size: 54px;
    line-height: 64px;
    font-weight: 700;
    margin-bottom: 45px;
    color: #002245;
    max-width: 100%;
  }

  .description {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #1A1A1A;
    margin-bottom: 24px;
  }

  .tu-van {
    position: relative;
    padding-top: 80px;
    padding-bottom: 30px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 2;

    .webform-ajax-form-wrapper {
      form {
        input[type="submit"] {
          margin: 32px 0 0 0;
        }
      }
    }
      .box-wrapper {
      padding-left: 50px;
    }

    .list-info {
      margin-bottom: 0px;
      padding: 0;
      list-style: none;
      @include flexbox();

      -ms-flex-wrap: wrap;
        flex-wrap: wrap;
      position: relative;
      margin-top: -20px;
      li {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
        margin: 20px 0;
        .item {
          @include flexbox();
          -ms-align-items: center;
          align-items: center;

          .icon {
            flex-shrink: 0;
            width: 80px;
            height: 80px;
            img {
              max-width: 100%;
              max-height: 100%;
              display: block;
              margin: 0 auto;
            }
          }
          .info {
            padding-left: 10px;
            flex-grow: 1;
            .order {
              font-weight: bold;
              font-size: 36px;
              line-height: 28px;
              text-align: center;
              color: #fff;
              margin-bottom: 4px;
              & > * {
                display: inline-block;
              }
            }
            .title {
              font-size: 16px;
              line-height: 24px;
              color: #ffffff;
            }
          }
        }
      }

    }
  }

  .tim-tho {
    position: relative;
    padding-bottom: 60px;
    .title-block {
      position: relative;
      .title-wrapper {
        background-image: url(../images/dynamic-images/home/home-find/home-tim-tho/tim-tho-title-bg.png);
        padding-top: 250px;
        padding-bottom: 50px;
        padding-left: 60px;
        margin-right: 40px;
        background-repeat: no-repeat;
        background-size: 100% auto;
        background-position: top 200px center;
        text-align: left;
        width: auto;
      }
    }
    .bg {
      content: "";
      position: absolute;
      left: 46%;
      right: 0;
      bottom: 0;
      height: 100%;
      z-index: -1;
      img {
        overflow: visible;
        object-position: 50% 100%;
      }
    }

    .content-block {
      padding-top: 6px;
      .icon {
        margin-bottom: 24px;
        width: 53px;
        height: 53px;

        img {
          display: block;
          width: 100%;
        }
      }
      [class*="col-"] {
        padding: 0 68px;
      }
      .content-wrapper {
        p {
          margin-bottom: 0;
        }
      }
    }

  }

  .phan-phoi {
    background-image: url(../images/dynamic-images/home/home-find/home-phan-phoi/phan-phoi-bg.png);
    background-repeat: no-repeat;
    background-position:top right;
    background-size: 40%;
    padding-bottom: 120px;

    .section-title {
      padding-top: 23px;
    }
    p {
      margin-bottom: 0;
    }

    .description {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }
    .search-form {
      margin-bottom: 0;
      position: relative;

      form {
        .group-form-type-select {
          display: flex;
          border-radius: 4px;
          margin: 35px 0;
          border: 1px solid rgba(152, 152, 154, 0.2);
        }

        .js-form-type-select {
          order: -1;
          padding: 14px 0;
          padding-right: 0;
          //max-width: 157px;
          width: 50%;
          background: #fff;

          &:first-child {
            border-radius: 4px 0 0 4px;
          }

          &:last-child {
            border-radius: 0 4px 4px 0;
          }


          select {
            height: auto;
            background-color: transparent;
            border: none;
            -webkit-appearance: none;
            -moz-appearance: none;
            background-image: url("../images/icons/arrow-down.png");
            background-position: right 15px center;
            background-size: 10px;
            background-repeat: no-repeat;
            width: 100%;
            padding-right: 30px;
            font-size: 14px;
            font-weight: 500;
            padding-left: 22px;
            @include border-radius(0);
            flex-shrink: 0;
          }

          &:not(:last-child) {
            select {
              border-right: 1px solid rgba(152, 152, 154, 0.2);
            }
          }
        }

        .btn-submit {
          background: #fdd000;
          box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.05);
          box-sizing: border-box;
          border-radius: 27px;
          font-weight: bold;
          font-size: 14px;
          line-height: 17px;
          text-align: center;
          color: #002245;
          padding: 16px 12px;
          width: 182px;
          max-width: 100%;
          margin: 0 auto;
          display: block;
          cursor: pointer;
          -ms-transition: background-color 0.4s ease;
          -o-transition: background-color 0.4s ease;
          -moz-transition: background-color 0.4s ease;
          -webkit-transition: background-color 0.4s ease;
          transition: background-color 0.4s ease;
          &:hover {
            background-color: #ffc107;
          }
        }
      }
    }

  }
}
@mixin home-find-md {
  .home-find {
    .tu-van {
      .box-wrapper {
        padding-left: 0;
      }
      .list-info{
        li {
          -ms-flex: 0 0 100%;
          flex: 0 0 100%;
          max-width: 100%;
          margin-bottom: 10px;
        }
      }
    }
    .tim-tho {
      .content-block {
        [class*="col-"] {
          padding: 0 34px;
        }
      }
    }
  }
}
@mixin home-find-sm {
  .home-find {
    .bg2 {
      img {
        max-width: none;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center top;
      }

    }
    .button-box {
      .btn-action {
        width: 100%;
      }
    }
    webform-ajax-form-wrapper {
      form {
        .form-item {
          margin-bottom: 10px;
        }
        input[type="submit"] {
          width: 100%;
        }
      }

    }
    .section-title {
      margin-bottom: 16px;
      font-size: 24px;
      font-weight: 700;
      line-height: 29px;
      text-align: center;
    }
    .main-title {
      font-size: 28px;
      line-height: 32px;
    }
    .description {
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      margin-bottom: 16px;
    }

    .tu-van {
      padding-bottom: 22px;

      .banner {
        padding-left: 20px;
        padding-right: 20px;
      }

      .webform-ajax-form-wrapper {
        form {
          input[type="submit"] {
            width: 100%;
          }
        }
      }
      .title-wrapper {
        width: 100%;
        padding: 32px 0 16px;
      }
    }

    .tim-tho {
      .bg {
        content: "";
        position: relative;
        padding: 0;
        left: 0;
        right: 0;
        top: 0;
        height: 100%;
        z-index: -1;
        img {
          overflow: visible;
          //object-position: 50% 100%;
        }
      }
      .title-block {
        position: relative;
        .title-wrapper {
          background-image: url(../images/dynamic-images/home/home-find/home-tim-tho/tim-tho-title-bg-m.png);
          padding: 58px 0 0;
          margin-right: 0;
          background-repeat: no-repeat;
          background-position: top 32px center;
          background-size: contain;
          text-align: left;
          width: auto;
        }
      }
      .button-box {
        .btn-action {
          margin: 0 auto;
        }
      }
      .content-block {
        padding-top: 12px;
        display: flex;
        justify-content: center;
        .icon {
          @include flexbox();
          align-items: center;
          justify-content: center;
          img {

          }
        }
        .content-wrapper {
          padding-bottom: 40px;
          @include flexbox();
          flex-direction: column;

          text-align: center;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;

          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
        }
      }
    }

    .phan-phoi {
      background-size: 85% auto;
      padding-bottom: 60px;

      .section-title {
        padding: 10px 32px 0;
        margin-bottom: 24px;
      }

      .search-form {

        form {
          .group-form-type-select {
            display: wrap;
            border-radius: 4px;
            margin: 16px 0 22px 0;
            border: none;
            @include flexbox;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
          }

          .js-form-type-select {
            border: 1px solid rgba(152, 152, 154, 0.2);
            width: 100%;
            margin-bottom: 10px;
            border-radius: 4px;
            &:first-child {
              border-radius: 4px;
            }
            &:last-child {
              border-radius: 4px;
            }

            &:not(:last-child) {
              select {
                border-right: none;
              }
            }

          }

          .btn-submit {
            width: 100%;
          }
        }
      }

    }

  }
}

@mixin home-find-xs {
  .home-find {
    .tu-van {
      padding: 0;
      padding-top: 80px;
      .col,
      .description {
        margin-bottom: 0;
      }
      .box-wrapper {
        padding: 32px 15px;
      }
      .list-info {
        margin: 0;
        li {
          margin-bottom: 0;
          margin-top: 0;
          & + li {
            margin-top: 24px;
          }
        }
      }
    }
  }
}
