.home-why {
  position: relative;
  background-color: #002245;
  .bg {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    right: 0;
    height: 100%;
    z-index: 1;
    img {
      max-width: none;
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover;
    }
    &:after {
      content: "";
      position: absolute;
      top:0;
      left: 0;
      right: 0;
      bottom: 0;
      background:rgba(0,34,69,0.8);
      background: -moz-linear-gradient(left, rgba(0,34,69,1) 0%, rgba(0,34,69,0.8) 100%);
      background: -webkit-linear-gradient(left, rgba(0,34,69,1) 0%,rgba(0,34,69,0.8) 100%);
      background: linear-gradient(to right, rgba(0,34,69,1) 0%,rgba(0,34,69,0.8) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#002245', endColorstr='#cc002245',GradientType=1 );
    }
  }
  .reason {
    position: relative;
    padding-top: 50px;
    padding-bottom: 30px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 2;
    .box-wrapper {
      padding-left: 50px;
    }
    .title-wrapper {
      width: 470px;
      max-width: 100%;
    }
    .section-title {
      margin-bottom: 24px;
      color: #ffffff;
      width: 331px;
      max-width: 100%;
    }

    .description {
      font-weight: normal;
      font-size: 14px;
      line-height: 24px;
      color: #ffffff;
      margin-bottom: 0px;
    }
    .list-info {
      margin-bottom: 0px;
      padding: 0;
      list-style: none;
      @include flexbox();
      
      -ms-flex-wrap: wrap;
        flex-wrap: wrap;
      position: relative;
      margin-top: -20px;
      li {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
        margin: 20px 0;
        .item {
          @include flexbox();
          -ms-align-items: center;
          align-items: center;
          
          .icon {
            flex-shrink: 0;
            width: 80px;
            height: 80px;
            img {
              max-width: 100%;
              max-height: 100%;
              display: block;
              margin: 0 auto;
            }
          }
          .info {
            padding-left: 10px;
            flex-grow: 1;
            .order {
              font-weight: bold;
              font-size: 36px;
              line-height: 28px;
              text-align: center;
              color: #fff;
              margin-bottom: 4px;
              & > * {
                display: inline-block;
              }
            }
            .title {
              font-size: 16px;
              line-height: 24px;
              color: #ffffff;
            }
          }
        }
      }
      
    }
  }
}
@mixin home-why-md {
  .home-why {
    .reason {
      .box-wrapper {
        padding-left: 0;
      }
      .list-info{
        li {
          -ms-flex: 0 0 100%;
          flex: 0 0 100%;
          max-width: 100%;
          margin-bottom: 10px;
        }
      }
    }
  }
}
@mixin home-why-sm {
  .home-why {
    
    .bg {
      left: 0;
      top: 30%;
      height: auto;
      bottom: 0;
      &:after {
        background: rgba(0, 34, 69, 0.8);
        background: -moz-linear-gradient(top, #002245 30%, rgba(0, 34, 69, 0.8) 100%);
        background: -webkit-linear-gradient(top, #002245 30%, rgba(0, 34, 69, 0.8) 100%);
        background: linear-gradient(to bottom, #002245 30%, rgba(0, 34, 69, 0.8) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#002245', endColorstr='#cc002245',GradientType=1 );
      }
    }
    .reason {
      padding-bottom: 22px;
      padding-top: 32px;
      .title-wrapper {
        width: 100%;
      }
      .section-title {
        margin-bottom: 16px;
      }
      .main-title {
        font-size: 28px;
        line-height: 32px;
      }
      .description {
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 32px;
      }
    }
  }
}

@mixin home-why-xs {
  .home-why {
    .reason {
      padding: 0;
      .col, 
      [class*=col-] {
        padding-right: 0;
        padding-left: 0;
      }
      .description {
        margin-bottom: 0;
      }
      .title-wrapper {
        padding: 34px 15px 32px;
        background-color: #002245;
      }
      .box-wrapper {
        padding: 32px 15px;
      }
      .list-info {
        margin: 0;
        li {
          margin-bottom: 0;
          margin-top: 0;
          & + li {
            margin-top: 24px;
          }
        }
      }
    }
    
    .bg:after {
      background: rgba(0, 34, 69, 0.95);
    }
  }
}