.post {
  .wrap-img {
    overflow: hidden;
    height: 330px;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover;
      @include transition(transform 0.5s ease);
    }
    .video-icon {
      position: absolute;
      bottom: 0;
      left: 0;
      background-color: rgba(112, 119, 129, .8);
      color: #fff;
      font-size: 12px;
      font-weight: 500;
      @include flexbox();
      -ms-align-items: center;
      align-items: center;
      .icon {
        background-color: #0E76A8;
        padding: 4px;
      }
      span {
        display: block;
        padding: 2px 7px;
      }
    }
    &:hover {
      img {
        @include transform(scale(1.1));
      }
    }
  }
  .catgories {
    list-style: none;
    padding-left: 0;
    margin-bottom: 4px;
    @include flexbox();
    
    
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    li {
      display: inline-block;
      a {
        display: inline-block;
        color: #707781;
        @include transition(color 0.4s ease);
        font-size: 12px;
        line-height: 20px;
        &:hover {
          color: #000;
        }
      }
      & + li {
        // margin-left: -4px;
        &:before {
          color: #707781;
          font-size: 12px;
          content: ", ";
        }
      }
    }
  }
  .content {
    background-color: #fff;
    padding: 32px 26px 28px;
    overflow: hidden;
    .title {
      margin-bottom: 24px;
      height: 56px;
      font-weight: bold;
      font-size: 24px;
      line-height: 28px;
      @include blockEllipsis(2);
      color: #1A1A1A;
      a {
        display: block;
        @include transition(color 0.4s ease);
        color: inherit;
      }
      &:hover {
        color: $hover;
      }
    }
    .desc {
      font-size: 16px;
      line-height: 24px;
      height: 72px;
      color: #1A1A1A;
      text-align: justify;
      text-align-last: left;
      @include blockEllipsis(3);
    }
    .btn-details {
      margin-top: 32px;
      display: inline-block;
      color: #161616;
      text-transform: uppercase;
      font-size: 14px;
      line-height: 20px;
      border-bottom: 1px solid #161616;
      font-weight: 600;
      @include transition(all 0.4s ease);
      &:hover {
        color: $hover;
        border-color: $hover;
      }
    }
  }
}
@mixin post-lg {
  .post {
    .wrap-img {
      height: 300px;
    }
    .content {
      padding: 15px;
      .title {
        font-size: 18px;
        line-height: 24px;
        height: 48px;
      }
    }
  }
}
@mixin post-md {
  .post {
    .wrap-img {
      height: 200px;
    }
  }
}
@mixin post-sm {
  .post {
    .wrap-img {
      height: 147px;
    }
    .content {
      .title {
        font-size: 16px;
        margin-bottom: 12px;
      }
      .desc {
        font-size: 14px;
        line-height: 20px;
        height: 60px;
      }
    }
  }
}