.home-counter {
  background-color: #fff;
  .counter-box {
    position: relative;
    z-index: 10;
    .sub-title {
      font-weight: bold;
      font-size: 36px;
      line-height: 1.4;
      color: #ffffff;
      margin-bottom: 40px;
    }
    .list-counter {
      margin-bottom: 0;
      
      -ms-flex-wrap: wrap;
        flex-wrap: wrap;
      padding-left: 0;
      list-style: none;
      justify-content: space-between;
      @include flexbox();
      li {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        max-width: 25%;
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        color: #fff;
        text-align: center;
        padding: 74px 15px;
        position: relative;
        .bg {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          overflow: hidden;
          img {
            max-width: none;
            width: auto;
            height: auto;
            min-width: 100%;
            min-height: 100%;
            position: absolute;
            right: 50%;
            bottom: 50%;
            @include transform(translate(50%, 50%));
          }
        }
        &:after {
          content: "";
          z-index: 0;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: #161616;
          opacity: 0.6;
        }
        .icon,
        .desc,
        .title {
          position: relative;
          z-index: 2;
        }
        .icon {
          height: 44px;
          img {
            max-height: 44px;
          }
        }
        .title {
          font-weight: bold;
          font-size: 60px;
          line-height: 140.62%;
          display: flex;
          align-items: center;
          display: block;
          margin-bottom: 4px;
        }
        .desc {
          font-weight: normal;
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
  }
}
@mixin home-counter-lg {
  .home-counter {
    .counter-box .list-counter li {
      padding: 50px 15px;
      .title {
        font-size: 45px;
      }
    }
  }
}
@mixin home-counter-md {
  .home-counter {
    .counter-box .list-counter li {
      padding: 40px 15px;
    }
    .counter-box .list-counter li .title {
      font-size: 35px;
    }
  }
}
@mixin home-counter-sm {
  .home-counter {
    .counter-box {
      .list-counter li {
        max-width: 50%;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        padding: 17px 15px 13px;
        .title {
          margin-top: 2px;
          font-size: 60px;
        }
      }
    }
  }
}
@mixin home-counter-xs {
  .home-counter .counter-box .list-counter li {
    max-width: 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    padding: 73px 15px;
  }
}
