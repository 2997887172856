@import "../includes/master/mixins.scss";
.webform-popup{
  .js-hide {
    display: none !important;
  }
  .form-item {
    margin-bottom: 16px;
    label {
      display: block;
      margin-bottom: 4px;
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      color: #1a1a1a;
      opacity: 0.3;
    }

    .form-text {
      margin-top: 0;
    }
    .form-item--error-message {
      color: #dc3545;
      font-size: 12px;
      line-height: 1.4;
      margin-top: 4px;
      strong {
        font-weight: normal;
      }
    }

    input,
    textarea,
    select {
      display: block;
      padding: 14px 16px;
      font-size: 14px;
      line-height: 20px;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      width: 100%;
      background: #fafbfc;
      // border: 1px solid #fdd000;
      -webkit-border-radius: 4px;
      border-radius: 4px;
      outline: none;
      @include transition(border-color 0.4s ease);
      @include placeholder {
        color: #1a1a1a;
        opacity: 0.3;
        font-style: 14px;
      }
      &:not(.error):placeholder-shown{
        border: 1px solid #f0f0f0;
      }
      &:not(.error):focus,
      &:not(.error):hover
      {
        box-shadow: none;
        border: 1px solid #fdd000;
      }
      &.error {
        border-color: #dc3545;
      }
    }
    select {
      background-image: url(../images/icons/arrow-down.png);
      background-repeat: no-repeat;
      background-position: right 12px center;
      padding-right: 30px;
      color: #1a1a1a;
    }
    input[type="text"],input[type="tel"] {
      cursor: text;
    }
    textarea {
      resize: none;
      height: 150px;
      cursor: text;
    }
    &.form-type-checkbox {
      input {
        display: none;
      }
      label {
        opacity: 1;
        margin-bottom: 0;
        font-size: 14px;
        line-height: 24px;
        color: #1a1a1a;
        position: relative;
        padding-left: 32px;
        cursor: pointer;
        &:after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 24px;
          height: 24px;
          background: #fff;
          border: 1.33px solid #fdd000;
          -webkit-border-radius: 4px;
          border-radius: 4px;
          @include transition(background-color 0.4s);
        }
      }
      input:checked + label {
        &:after {
          background-color: #fdd000;
          border-color: #fdd000;
          background-image: url('data:image/svg+xml,<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="pajamas:check-sm"><path id="Vector" fill-rule="evenodd" clip-rule="evenodd" d="M12.5688 3.04496C12.7326 3.20902 12.8247 3.43142 12.8247 3.66329C12.8247 3.89517 12.7326 4.11756 12.5688 4.28162L5.45562 11.3948C5.37351 11.4768 5.27589 11.5417 5.16845 11.5856C5.06101 11.6296 4.94591 11.6517 4.82984 11.6507C4.71377 11.6498 4.59905 11.6257 4.49237 11.58C4.38569 11.5342 4.28918 11.4677 4.20846 11.3843L1.40496 8.48629C1.24508 8.31923 1.15783 8.09567 1.16227 7.86448C1.16672 7.63329 1.2625 7.41325 1.42868 7.25246C1.59487 7.09167 1.81794 7.0032 2.04916 7.00639C2.28037 7.00957 2.50093 7.10415 2.66262 7.26946L4.84779 9.52812L11.3321 3.04496C11.4962 2.8811 11.7186 2.78906 11.9505 2.78906C12.1823 2.78906 12.4047 2.8811 12.5688 3.04496Z" fill="%23191919"/></g></svg>');
          background-position: center;
          background-repeat: no-repeat;
        }
      }
    }
    &.form-type-webform-image-file {
      .webform-element-description {
        margin-bottom: 8px;
        h4 {
          font-weight: 700;
          font-size: 18px;
          line-height: 24px;
          color: #000;
          margin-bottom: 8px;
        }
      }
      .form-managed-file {
        @include flexbox;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        margin: 0 -8px;
        .js-form-file.form-file {
          display: none;
        }
        .webform-managed-file-preview-wrapper {
          padding: 0 8px;
          margin-bottom: 10px;
          -ms-flex: 0 0 25%;
          flex: 0 0 25%;
          max-width: 25%;
          label {
            padding: 5px;
            border: 1px solid rgba(152, 152, 154, 0.2);
            -webkit-border-radius: 4px;
            border-radius: 4px;
            @include transition(border-color 0.4s ease);
            height: 140px;
            @include flexbox;
            -webkit-box-align: center;
            -webkit-align-items: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: center;
            -webkit-justify-content: center;
            -ms-flex-pack: center;
            justify-content: center;
            &:after {
              top: 8px;
              left: 8px;
            }
            img {
              display: block;
              max-width: 100%;
            }
          }
          input:checked + label {
            border-color: #002245;
          }
        }
        .form-submit {
          margin: 0 8px 10px;
          background-color: #dc3545;
          border-color: #dc3545;
          color: #fff;
          @include transition(background-color 0.4s ease, color 0.4s ease);
          &:hover {
            color: #fff;
            background-color: #c82333;
            border-color: #bd2130;
          }
        }
      }
      & > label {
        display: inline-block;
        margin-bottom: 0;
        background: rgba(242, 242, 242, 0.5);
        border: 1px solid #ebebeb;
        -webkit-border-radius: 4px;
        border-radius: 4px;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        color: #c4c4c4;
        padding: 54px 6px 22px;
        cursor: pointer;
        -ms-transition: border-color 0.4s ease;
        -o-transition: border-color 0.4s ease;
        -moz-transition: border-color 0.4s ease;
        -webkit-transition: border-color 0.4s ease;
        transition: border-color 0.4s ease;
        background-image: url(../images/icons/add-file.svg);
        background-repeat: no-repeat;
        background-position: top 15px center;
        @include transition(background-color 0.4s, color 0.4s);
        &:hover {
          background-color: #002245;
          color: #fff;
        }
      }
    }
    &.form-type-managed-file {
      .webform-element-description {
        margin-bottom: 8px;
        h4 {
          font-weight: 700;
          font-size: 18px;
          line-height: 24px;
          color: #000;
          margin-bottom: 8px;
        }
      }
      .form-managed-file {
        .js-form-file.form-file {
          display: none;
        }
        .form-item {
          margin-bottom: 8px;
          a {
            color: #1a1a1a;
            text-decoration: underline;
            @include transition(color 0.4s ease);
            &:hover {
              color: #fdd000;
            }
          }
        }
        .form-submit {
          margin-bottom: 10px;
          background-color: #dc3545;
          border-color: #dc3545;
          color: #fff;
          @include transition(background-color 0.4s ease, color 0.4s ease);
          &:hover {
            color: #fff;
            background-color: #c82333;
            border-color: #bd2130;
          }
        }
      }

      & > label {
        margin-bottom: 0;
        cursor: pointer;
        display: inline-block;
        padding: 6px 20px;
        background-color: #fdd000;
        -webkit-border-radius: 4px;
        border-radius: 4px;
        color: #000;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        background-image: url("../images/icons/add-file-white.svg");
        background-repeat: no-repeat;
        background-position: left 5px center;
        background-size: 18px;
        padding-left: 30px;
        @include transition(background-color 0.4s, color 0.4s);
        &:hover {
          background-color: #002245;
          color: #fff;
        }
      }
    }
  }
  .form-radios {
    margin-bottom: 16px;
    .form-type-radio {
      margin-bottom: 8px;
      input {
        display: none;
      }
      label {
        margin-bottom: 0;
        font-size: 16px;
        line-height: 20px;
        color: #1a1a1a;
        position: relative;
        padding-left: 30px;
        cursor: pointer;
        &:after {
          content: "";
          position: absolute;
          top: 4px;
          left: 4px;
          width: 12px;
          height: 12px;
          background: #999;
          -webkit-border-radius: 50%;
          border-radius: 50%;
          -ms-transition: background-color 0.4s ease;
          -o-transition: background-color 0.4s ease;
          -moz-transition: background-color 0.4s ease;
          -webkit-transition: background-color 0.4s ease;
          transition: background-color 0.4s ease;
        }
        &:before {
          content: "";
          position: absolute;
          top: 0px;
          left: 0;
          width: 20px;
          height: 20px;
          border: 1px solid #999;
          -webkit-border-radius: 50%;
          border-radius: 50%;
          -ms-transition: border-color 0.4s ease;
          -o-transition: border-color 0.4s ease;
          -moz-transition: border-color 0.4s ease;
          -webkit-transition: border-color 0.4s ease;
          transition: border-color 0.4s ease;
        }
      }
      input:checked + label {
        &:after {
          background-color: #fdd000;
        }
        &:before {
          border-color: #fdd000;
        }
      }
    }
  }
  .form-actions {
    input {
      background: #fdd000;
      border: 1px solid rgba(152, 152, 154, 0.2);
      box-sizing: border-box;
      border-radius: 27px;
      @include transition(all 0.4s);
      font-weight: bold;
      font-size: 14px;
      padding: 12px 10px;
      min-width: 150px;
      &:not(:disabled):hover {
        background-color: #ffc107;
      }
      &:disabled {
        background: #e1e1e1;
        cursor: not-allowed;
        border: 1px solid rgba(152, 152, 154, 0.2);
        color: #98989a;
      }
    }
  }
  .modal-dialog{
    max-width: 500px;
    .modal-content{
      padding-bottom: 16px;
      border-radius: 16px;
      border: 0;
      .hero{
        border-radius: 16px 16px 0 0;
        margin: -16px -16px 16px -16px;
        overflow: hidden;
        img{
          max-width: 100%;
          width: 100%;
        }
      }
      .close{
        display: flex;
        padding: 8px;
        align-items: center;
        position: absolute;
        right: 16px;
        top: 16px;
        border-radius: 50%;
        background: rgba(0, 0, 0, 0.30);
      }
      .main-content{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: -7px;
        margin-left: -7px;
        position: relative;
        .main-content__title{
          display: flex;
          flex-direction: column;
          max-width: 372px;
          margin: auto;
          align-items: center;
          gap: 10px;
          margin-bottom: 18px;
          align-self: stretch;
          .main-content__title-1{
            color: var(--black-900, #191919);
            text-align: center;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            opacity: 0.8;
            margin-bottom: 0;
          }
          .main-content__title-2{
            color: #464646;
            text-align: center;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-bottom: 0;
            opacity: 0.8;
          }
        }
        form{
          // overflow-y: scroll;
          // height: 404px;
          width: 100%;
          .div-scroll{
            overflow-y: scroll;
            height: 350px;
            width: 100%;
            margin-bottom: 28px;
            -webkit-overflow-scrolling: touch;
            &::-webkit-scrollbar-track {
              -webkit-border-radius: 3px;
              border-radius: 3px;
              background-color: #fff;
              position: absolute;
              left: 100px;
            }
  
            &::-webkit-scrollbar {
              width: 5px;
              background-color: #fff;
              -webkit-border-radius: 3px;
              border-radius: 3px;
              position: absolute;
              left: 100px;
            }
            &::-webkit-scrollbar-thumb {
              -webkit-border-radius: 3px;
              border-radius: 3px;
              background-color: #e6e6e6;
              position: absolute;
              left: 100px;
            }  
            input{
              &::placeholder{
              font-family: 'Helvetica';
              font-weight: 500;
              }
            }
            .checkbox-div{
              gap:40px;
              padding-top: 8px;
              padding-bottom: 8px;
              height: 40px;
              margin-bottom: 16px;
            }
            >* {
              max-width: 386px;
              margin: auto;
              margin-bottom: 16px;
              padding-left: 7px;
              padding-right: 7px;
              &:last-child{
                margin-bottom: 0px;
              }
            }
            .checkbox-title{
              margin-bottom: 4px;
              span{
                display: block;
                font-weight: 500;
                font-size: 12px;
                line-height: 20px;
                color: #1a1a1a;
                opacity: 0.3;
              }
            }
          }
          .form-actions{
            text-align: center;
          }
        }
      }
    }
    .thankyou-content{
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 372px;
      margin: auto;
      align-self: stretch;
      padding-top: 27px;
      .thankyou-content__title1{
        color: #191919;
        text-align: center;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        opacity: 0.8;
        margin-bottom: 16px;
      }
      .thankyou-content__title2{
        color: #464646;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 32px;
        opacity: 0.8;
      }
      .btn-action{
        background: #fdd000;
        box-sizing: border-box;
        border-radius: 27px;
        -ms-transition: all 0.4s;
        -o-transition: all 0.4s;
        -moz-transition: all 0.4s;
        -webkit-transition: all 0.4s;
        transition: all 0.4s;
        font-weight: bold;
        font-size: 14px;
        padding: 16px 80px;
        min-width: 150px;
        text-align: center;
        line-height: 18px;
      }
    }
  }
  @media (min-width:992px) {
    .modal-dialog{
      margin-top: 37.5px;
    }
  }
}
