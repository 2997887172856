.home-infographic {
  background-color: #fff;
  padding-top: 64px;
  padding-bottom: 32px;

  .top-content {
    margin-bottom: 24px;
    color: #000000;
    .logo {
      margin-bottom: 16px;
    }
    .title {
      margin-bottom: 4px;
      font-weight: bold;
      font-size: 24px;
      line-height: 28px;
    }
    .sub-title {
      font-weight: normal;
      font-size: 18px;
      line-height: 22px;
      color: #161616;
    }
  }
  .dynamic-content {
    color: #484848;
    font-size: 16px;
    line-height: 24px;
    opacity: .8;
    p {
      margin-bottom: 12px;
    }
  }
  .btn-viewmore {
    font-weight: normal;
    font-size: 18px;
    line-height: 28px;
    color: #002245;
    border-color: #002245;
    &:hover {
      color: $hover;
      border-color: $hover;
    }
  }
  .main-content {
    -ms-flex-align: center;
    align-items: center;
    .banner {
      margin-bottom: 24px;
      img {
        max-width: 100%;
        display: block;
        margin: 0 auto;
        max-height: 288px;
      }
    }
    .list-info {
      @include flexbox();
      justify-content: center;
      .item {
        padding: 0 20px;
        .title {
          margin-bottom: 0px;
          font-weight: 500;
          font-size: 14px;
          line-height: 1.43;
          text-transform: uppercase;
          color: #1a1a1a;
        }
        .count {
          font-weight: bold;
          font-size: 36px;
          line-height: 1.11;

          background: rgb(239, 64, 63);
          background: -moz-linear-gradient(
            top,
            rgba(239, 64, 63, 1) 0%,
            rgba(251, 192, 25, 1) 100%
          );
          background: -webkit-linear-gradient(
            top,
            rgba(239, 64, 63, 1) 0%,
            rgba(251, 192, 25, 1) 100%
          );
          background: linear-gradient(
            to bottom,
            rgba(239, 64, 63, 1) 0%,
            rgba(251, 192, 25, 1) 100%
          );
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ef403f', endColorstr='#fbc019',GradientType=0 );

          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
        &:nth-child(2) {
          .count {
            background: rgb(138, 195, 174);
            background: -moz-linear-gradient(
              top,
              rgba(138, 195, 174, 1) 0%,
              rgba(232, 216, 56, 1) 100%
            );
            background: -webkit-linear-gradient(
              top,
              rgba(138, 195, 174, 1) 0%,
              rgba(232, 216, 56, 1) 100%
            );
            background: linear-gradient(
              to bottom,
              rgba(138, 195, 174, 1) 0%,
              rgba(232, 216, 56, 1) 100%
            );
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#8ac3ae', endColorstr='#e8d838',GradientType=0 );
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
      }
    }
  }
}
@mixin home-infographic-sm {
  .home-infographic {
    padding-bottom: 0;
    padding-top: 48px;
    .top-content {
      margin-bottom: 24px;
    }
    .btn-viewmore {
      font-size: 14px;
      line-height: 17px;
    }
    .main-content {
      padding-top: 69px;
      border-bottom: 1px solid rgba(0, 36, 71, 0.21);
      padding-bottom: 32px;
      .list-info {
        padding-top: 18px;
        .item {
          width: 50%;
          padding: 0 5px;
          .title {
            margin-bottom: 0;
            font-size: 14px;
          }
          .count {
            font-size: 38px;
            line-height: 40px;
          }
        }
      }
    }
  }
}
@mixin home-infographic-sp {
  .home-infographic {
    .main-content .list-info .item .count {
      font-size: 28px;
    }
    .main-content .list-info .item .title {
      font-size: 13px;
    }
  }
}
