.product {
  height: 100%;
  background-color: #FAFAFA;
  position: relative;
  z-index: 0;
  @include transition(all 0.3s ease);
  
  &:hover {
    .image {
      img {
        @include transform(scale(1.2));
        
      }
    }
  }
  .badges {
    .badge {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      width: auto;
      display: inline-block;
      &.new {
        display: block;
        left: auto;
        right: 16px;
        top: 16px;
      }
    }
  }
  .badge {
    padding: 0;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    top: 0;
    img {
      display: inline-block;
    }
  }
  .image {
    padding: 24px;
    overflow: hidden;
  }
  .image a { 
    overflow: hidden;
    height: 187px;
    @include flexbox();
    align-items: center;
    justify-content: center;
    img {
      max-height: 100%;
      display: block;
      max-width: 100%;
      height: auto;
      @include transition(transform 0.4s ease);
    }
  }
  .categories {
    min-height: 94px;
    padding: 0 24px;
    ul {
      padding-left: 0;
      margin-bottom: 0px;
      @include flexbox();
      align-items: center;
      -ms-flex-wrap: wrap;
        flex-wrap: wrap;
      li {
        margin: 0 0 16px;
        width: 50%;
        display: inline-block;
        .cat {
          position: relative;
          @include flexbox();
          align-items: center;
          .icon {
            flex-shrink: 0;
            width: 24px;
            height: 24px;
            @include border-radius(50%);
            background-color: $main;
            @include flexbox();
            align-items: center;
            justify-content: center;
          }
          span {
            display: inline-block;
            padding-left: 8px;
            font-size: 12px;
            line-height: 16px;
          }
          a {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }
        }
        &:hover {
          .icon {
            animation: tada 1s 1;
          }
        }
      }
    }
  }
  .title {
    padding: 7px 0;
    border-top: 1px solid rgba(0, 34, 69, .05);
    margin-left: 24px;
    margin-right: 24px;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 0;
    color: $dark-blue;
    @include transition(color 0.3s ease);
    a {
      color: inherit;
      display: block;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      height: 44px;
    }
    &:hover {
      color: $hover
    }
  }
  
}
@mixin product-xs {
  .product {
    .image a {
      height: 135px;
    }
    .title {
      font-size: 14px;
      line-height: 1.67;
    } 
    .badges .badge {
      left: 14px;
      &.bestseller {
        width: 44px;
      }
      &.new {
        top: 14px;
        right: 14px;
      }
    }
    .categories {
      .cat .icon {
        width: 40px;
      }
    }
  }
}
