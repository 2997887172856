.suggest-product {
  height: 100%;
  background-color: #FAFAFA;
  position: relative;
  z-index: 0;
  @include transition(all 0.3s ease);
  box-shadow: 0px 10px 15px 0px #0000000F;
  border-radius: 4px;
  overflow: hidden;

  .badges {
    .badge {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      width: auto;
      display: inline-block;
      &.new {
        display: block;
        left: auto;
        right: 16px;
        top: 16px;
      }
    }
  }
  .badge {
    padding: 0;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    top: 0;
    img {
      display: inline-block;
    }
  }
  .image {
    padding: 24px 20px 20px 20px;
    overflow: hidden;
  }
  .image a { 
    overflow: hidden;
    height: 168px;
    @include flexbox();
    align-items: center;
    justify-content: center;
    img {
      max-height: 100%;
      display: block;
      max-width: 100%;
      height: auto;
      @include transition(transform 0.4s ease);
    }
  }
  .title {
    padding: 12px 0 0 0;
    border-top: 1px solid rgba(0, 34, 69, .05);
    margin-left: 20px;
    margin-right: 20px;
    font-weight: 700;
    font-size: 19px;
    line-height: 26px;
    margin-bottom: 0;
    color: $dark-blue;
    @include transition(color 0.3s ease);
    a {
      color: inherit;
      display: block;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      height: 52px;
    }
    &:hover {
      color: $hover
    }
  }
  .button-box {
    padding: 17px 20px 24px;
    .btn-action {
      background: #FAFAFA;
      border: 1px solid #002245;
      box-sizing: border-box;
      border-radius: 4px;
      font-weight: 500;
      font-size: 17px;
      line-height: 24px;
      text-align: center;
      color: #002245;
      padding: 10px 0;
      //width: 182px;
      height: 46px;
      max-width: 100%;
      display: block;
      cursor: pointer;
      -ms-transition: background-color 0.4s ease;
      -o-transition: background-color 0.4s ease;
      -moz-transition: background-color 0.4s ease;
      -webkit-transition: background-color 0.4s ease;
      transition: background-color 0.4s ease;
    }
  }

}
@mixin suggest-product-sm {
  .suggest-product {
    .image {
      padding: 20px 20px 16px 20px;
    }
    .image a {
      height: calc(197 / 375 * 100vw );
    }
    .title {
      font-size: 20px;
      font-weight: 700;
      line-height: 22px;
    }
    a {
      height: 44px;
    }
    .button-box {
      padding: 18px 20px 20px;
      .btn-action {
        background: #fdd000;
        border: none;
        font-size: 20px;
        font-weight: 500;
        line-height: 24px;
        padding: 10px 0;
        //width: 182px;
        height: 44px;
      }
    }

  }
}
