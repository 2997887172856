.home-contact {
  position: relative;
  z-index: 20;
  background-color: #fff;
  padding-bottom: 95px;
  .contact-now {
    background-size: cover;
    background-position: center;
    color: #fff;
    position: relative;
    z-index: 0;
    // &:after {
    //   content: "";
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   width: 100%;
    //   height: 100%;
    //   background-color: #002245;
    //   opacity: .9;
    //   z-index: -1;
    // }
    .bg {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      overflow: hidden;
      img {
        max-width: none;
        width: auto;
        height: auto;
        min-width: 100%;
        min-height: 100%;
        position: absolute;
        right: 50%;
        bottom: 50%;
        @include transform(translate(50%, 50%));
      }
    }
    .image-box {
      position: relative;
      .image {
        z-index: 2;
        display: block;
        position: absolute;
        bottom: -95px;
        left: 0;
        right: 0;
        margin: 0 auto;
        max-width: 100%;
        max-height: 525px;
        width: 486px;
        
        img {
          margin: 0 auto;
          display: block;
          max-width: 100%;
          max-width: 100%;
          max-height: 100%;
        }
      }
    }
    .info-container {
      padding: 133px 0;
      .content {
        padding: 32px 70px 26px;
        position: relative;
        background-image: url('../images/dynamic-images/home/home-contact/bg-info.png');
        background-size: 100% auto;
        background-position: top left;
        background-repeat: no-repeat;
        .wrapper {
          position: relative;
          z-index: 3;
        }
        .totals {
          float: left;
          font-weight: bold;
          font-size: 60px;
          line-height: (73 / 60);
          color: $main;
        }
        .title {
          padding-top: 2px;
          overflow: hidden;
          flex-grow: 1;
          font-weight: 500;
          font-size: 28px;
          line-height: (32 / 28);
          color: #ffffff;
          margin-bottom: 40px;
          padding-left: 35px;
        }
        .button-box {
          clear: both;
          width: 100%;
          text-align: center;
          .btn-action {
            font-weight: bold;
            font-size: 14px;
            line-height: 1.43;
            text-align: center;
            color: $black;
            display: inline-block;
            padding: 16px 40px;
            @include border-radius(26px);
            background-color: #FDD000;
            position: relative;
            z-index: 0;
            min-width: 184px;
            &:after {
              z-index: -1;
              content: "";
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              @include border-radius(26px);
              background-color: #fff;
              @include transform(scaleX(0));
              @include transition(transform 0.4s ease);
            }
            &:hover {
              &:after {
                @include transform(scaleX(1));
              }
            }
          }
        }
      }
    }
  }
}

@mixin home-contact-lg {
  .home-contact {
    padding-bottom: 0;
    .contact-now {
      padding-top: 40px;
    }
    .contact-now .info-container {
      padding: 10px 0;
    }
    .contact-now .image-box .image {
      position: static;
    }
  }
}
@mixin home-contact-sm {
  .home-contact {
    padding-bottom: 65px;

    .contact-now {
      .image-box {
        order: 1;
        @include transform(translateY(72px));
      } 
      .info-container {
        padding-top: 0;
        margin-bottom: -72px;
        .content {
          padding: 15px;
          .title {
            font-size: 18px;
            padding-left: 12px;
            margin-bottom: 15px;
          }
          .totals {
            font-size: 70px;
          }
        }
      }
    }
  }
}
@mixin home-contact-sp {
  .home-contact {
    .contact-now {
      .info-container {
        .content {
          .title {
            font-size: 16px;
            padding-left: 12px;
            margin-bottom: 15px;
          }
        }
      }
    }
  }
}